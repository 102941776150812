<template>
    <div
        :id="modalHistory"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        History Cancelled Appointment
                        {{dokter}}
                        {{poli}}
                        <!-- {{ dokter ? dokter.nama : poli.nama }} -->
                    </h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div
                        v-if="showLoading"
                        class="my-auto p-5 d-flex flex-row h-100"
                    >
                        <div
                            class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"
                        ></div>
                        <h5 class="my-auto pl-5">Loading ...</h5>
                    </div>
                    <div v-if="isLoaded">
                        <data-tables :table="table"></data-tables>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="close"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { RepositoryFactory } from "./../../../../repositories/RepositoryFactory";
const ReservationRepository = RepositoryFactory.get("reservation");
import SweetAlertTemplate from "./../../../_general/SweetAlert";
const SweetAlert = Vue.extend(SweetAlertTemplate);
import BlockUI from "./../../../_js/BlockUI";
const blockUI = new BlockUI();
import DataTables from "./../../../_table/DataTables";
import BtnAction from "./BtnAction";
export default {
    data() {
        var vx = this;
        return {
            isLoaded: false,
            showLoading: true,
            tableName: "table_queue_user",
            table: {
                order: [[1, "desc"], [2, "desc"]],
                tableName: "table_queue_user",
                tableDataResult: [],
                tableColumns: [
                    {
                        title: "ID",
                        data: "id"
                    },
                    {
                        title: "Tanggal Kunjungan",
                        data: "tanggalKunjungan"
                    },
                    {
                        title: "Waktu Dibuat",
                        data: "createdDate"
                    },
                    {
                        title: "No Antrian",
                        data: function(row, type) {
                            if(row.queue) {
                                if(row.queue.queueNoFormatted){
                                    return row.queue.queueNoFormatted
                                }
                            }
                            return ' '
                            }
                    },
                    {
                        title: "Nama Pasien",
                        data: "namaPasien"
                    },
                    {
                        title: "Tanggal Lahir",
                        data: "tanggalLahir"
                    },
                    {
                        title: "No Telp",
                        data: "telp"
                    },
                    {
                        title: "Cancel Status",
                        data: "cancelStatus"
                    },
                    {
                        title: "Actions",
                        data: "id",
                        createdCell: function(
                            cell,
                            cellData,
                            rowData,
                            rowIndex,
                            colIndex
                        ) {
                            let btnAction = Vue.extend(BtnAction);
                            let instance = new btnAction({
                                propsData: {
                                    historyTableVue: vx,
                                    rowData: rowData,
                                    modalHistory: modalHistory
                                }
                            });
                            instance.$mount();
                            if (rowData.cancelStatus == "NEED_CONFIRMATION") {
                                $(cell)
                                    .empty()
                                    .append(instance.$el);
                            } else {
                                $(cell).empty();
                            }
                        }
                    }
                ],
                tableColumnDefs: [
                    {
                        targets: [0],
                        visible: false
                    },
                    {
                        targets: 1,
                        render: function(t, e, a, n) {
                            var momentDate = moment(t);
                            return momentDate.format("DD/MM/YYYY");
                        }
                    },
                    {
                        targets: 2,
                        render: function(t, e, a, n) {
                            var momentDate = moment(t);
                            return momentDate.format("DD/MM/YYYY HH:mm:ss");
                        }
                    },
                    {
                        targets: 6,
                        render: function(t, e, a, n) {
                            var i = {
                                AUTO: {
                                    title: "Auto",
                                    state: "info"
                                },
                                MANUAL: { title: "Manual", state: "primary" }
                            };
                            return void 0 === i[t]
                                ? t
                                : '<span class="kt-badge kt-badge--' +
                                      i[t].state +
                                      ' kt-badge--dot"></span>&nbsp;' +
                                      '<span class="kt-font-bold kt-font-' +
                                      i[t].state +
                                      '">' +
                                      i[t].title +
                                      "</span>";
                        }
                    },
                    {
                        targets: 7,
                        className: "text-center",
                        render: function(t, e, a, n) {
                            var i = {
                                NEED_CONFIRMATION: {
                                    title: "NEED_CONFIRMATION",
                                    class: "kt-badge--brand"
                                },
                                RESCHEDULED: {
                                    title: "RESCHEDULED",
                                    class: "kt-badge--success"
                                },
                                DISMISS: {
                                    title: "DISMISS",
                                    class: "kt-badge--warning"
                                }
                            };
                            return void 0 === i[t]
                                ? t
                                : '<span class="kt-badge ' +
                                      i[t].class +
                                      ' kt-badge--inline kt-badge--pill">' +
                                      i[t].title +
                                      "</span>";
                        }
                    },
                    {
                        targets: -1,
                        responsivePriority: 1
                    }
                ],
                tableFilter: [
                    {
                        title: "Tanggal Kunjungan",
                        type: "datepicker"
                    },
                    {
                        title: "Waktu Dibuat",
                        type: "datepicker"
                    },
                    {
                        title: "No Antrian",
                        type: "text"
                    },
                    {
                        title: "Nama Pasien",
                        type: "text"
                    },
                    {
                        title: "Tanggal Lahir",
                        type: "datepicker"
                    },
                    {
                        title: "Tipe Antrian",
                        type: "combobox"
                    },
                    {
                        title: "Cancel Status",
                        type: "combobox"
                    },
                    {
                        title: "Actions",
                        type: "actions"
                    }
                ]
            }
        };
    },
    components: {
        DataTables
    },
    model: {
        prop: "value",
        event: "change"
    },
    props: {
        modalHistory: { type: String, default: "modalHistory" }
    },
    computed: {
        ...mapState("DoctorAppointmentStore", {
            date: state => state.date,
            dokter: state => state.dokter,
            poli: state => state.poli,
            dokterArray: state => state.dokterArray
        })
    },
    methods: {
        close() {
            $("#" + this.modalHistory).modal("hide");
            this.setShowModalHistory(false);
        },
        ...mapMutations({
            setShowModalHistory: "DayCalendarStore/SET_SHOW_MODAL_HISTORY",
            setShowModalReservasi:
                "DoctorAppointmentStore/SET_SHOW_MODAL_RESERVASI",
            setSelectedEvent: "DayCalendarStore/SET_SELECTED_EVENT"
        }),
        async fetch(resolve) {
            // fetch history dari appointment dokter yang dipilih sesuai tanggal yang dipilih
            let payload = {
                "tanggalKunjungan.equals": this.date,
                "status.in": "CANCEL",
            }

            if (this.dokter) {
                // payload = {
                //     "dokterId.equals": this.dokter.id
                // };
                payload["dokterId.equals"] = this.dokter.id
            } else if (this.poli) {
                // payload = {
                //     "tujuanRujukanId.in": this.poli.id
                // };
                payload["tujuanRujukanId.in"] = this.poli.id
            }

            const dokterIds = this.dokterArray.map(dokter => dokter.id)
            new Promise((resolve, reject) => {
                ReservationRepository.get(payload, resolve, reject , {type: 'dokterId.equals' , dokterIds});
            })
                .then(({ data }) => {
                    if (this.isLoaded) {
                        var datatable = $("#" + this.tableName).DataTable();
                        datatable.clear();
                        datatable.rows.add(data);
                        datatable.draw("full-hold");
                        if (resolve !== undefined) {
                            resolve(data);
                        }
                    } else {
                        this.table.tableDataResult = data;
                        this.showLoading = false;
                        this.isLoaded = true;
                    }
                })
                .catch(error => {
                    var msg = error.message ? error.message : error;
                });
        }
    },
    mounted() {
        $("#" + this.modalHistory).modal();
        this.fetch();
    }
};
</script>

<style lang="scss" scoped></style>
