export default function Reservasi() {
    return {
        id: null,
        nama: null,
        namaPasien: null,
        createdVia: null,
        nomorMedicalRecord: null,
        tanggalLahir: null,
        tanggalKunjungan: null,
        email: null,
        telp: null,
        pesan: null,
        nomorAntrian: null,
        estimasiWaktu: null,
        isMRReady: false,
        isDeleted: false,
        gender: null,
        jadwal: null,
        jenisPenjamin:null,
        status: null,
        patient: null,
        queueId: null,
        jamMulai: null,
        jamSelesai: null
    };
}
