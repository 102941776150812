<template>
    <div class="text-center">
        <div>
            <button
                class="btn btn-default text-uppercase w-100"
                @click.prevent="reschedule"
            >
                Reschedule
            </button>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { RepositoryFactory } from "../../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../../_general/SweetAlert";
import BlockUI from "../../../_js/BlockUI";
import Reservasi from "./../../../../model/reservasi-model";
const QueueResourceRepository = RepositoryFactory.get("queueResource");
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
    data() {
        return {
            reservasi: new Reservasi()
        };
    },
    props: {
        historyTableVue: { type: Object },
        rowData: { type: Object }
    },
    computed: {
        start() {
            let str = this.rowData.queue.queueNoFormatted.slice(1);
            return str.substring(0, 2) + ":" + str.slice(2);
        },
        end() {}
    },
    methods: {
        reschedule() {
            var object = {};
            object.start = null;
            object.end = null;
            object.jadwal = this.rowData.jadwal;
            object.reservasi = this.rowData;
            object.type = this.rowData.status;
            this.historyTableVue.setSelectedEvent(object);
            // this.historyTableVue.$el.style.zIndex = 1040;
            this.historyTableVue.close();
            this.historyTableVue.setShowModalReservasi(true);
        }
    },
    mounted() {
        this.historyTableVue.setSelectedEvent(null);
    }
};
</script>
